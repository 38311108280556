import constants from '@/constants';
import { titleSizes } from '@/utils/constants/titleSizes';

export const defaultCoverImage =
	'https://staging-azent-website-cms-uploads.s3.amazonaws.com/Image_198_f8f1cce31c.png?w=1200';

export const scholarshipFilterSectionConfig = {
	id: 1,
	__component: 'scholarship-page.scholarship-section',
};
export const scholarshipGroupSectionConfig = {
	id: 1,
	__component: 'scholarship-page.scholarship-section',
	labelOnNavigation: 'Scholarship',
};

export const bannerSectionConfig = {
	id: 1,
	__component: 'university-page.university-page-banner-section',
	titleFontSize: 'Large',
};

export const detailBannerSectionConfig = {
	id: 1,
	__component: 'scholarship-page.scholarship-page-detail-banner-section',
	titleFontSize: 'Small',
};

export const modalFormCtaSectionConfig = {
	id: 2,
	__component: 'shared.login-cta-section',
	title: 'Your Study Abroad Dream Is Just A Click Away',
	titleFontSize: 'Medium',
	subTitle: "The only app you'll need for your entire journey",
	mobileTitle: 'Your Study Abroad Dream Is Just A Click Away',
	mobileSubTitle: "The only app you'll need for your entire journey",
	ctaButton: { label: 'Start Now', openFormModal: true },
};

export const aboutScholarshipGeneralConfig = {
	id: 1,
	__component: 'shared.content',
	title: 'General Details',
	titleFontSize: 'Small',
	styled: true,
	labelOnNavigation: 'General Details',
};

export const aboutScholarshipEligibilityConfig = {
	id: 2,
	__component: 'shared.content',
	title: 'Eligibility',
	titleFontSize: 'Small',
	styled: true,
	labelOnNavigation: 'Eligibility',
};

export const aboutScholarshipAboutConfig = {
	id: 3,
	__component: 'shared.content',
	title: 'Additional Info',
	titleFontSize: 'Small',
	styled: true,
	labelOnNavigation: 'Additional Info',
};

export const aboutScholarshipContactConfig = {
	id: 4,
	__component: 'shared.content',
	title: 'Contact Info',
	titleFontSize: 'Small',
	styled: true,
	labelOnNavigation: 'Contact Info',
};

export const aboutScholarshipApplicationConfig = {
	id: 5,
	__component: 'shared.content',
	title: 'Application Process',
	titleFontSize: 'Small',
	styled: true,
	labelOnNavigation: 'Application Process',
};

export const aboutUpdatedOnConfig = {
	id: 1,
	__component: 'shared.info-note',
};

export const counsellorCtaSectionConfig = {
	id: 1,
	__component: 'shared.login-cta-section',
	title: 'To find the best scholarship, talk to our counselors today!',
	titleFontSize: titleSizes.medium,
	mobileTitle: 'To find the best scholarship, talk to our counselors today! ',
	ctaButton: {
		label: 'Connect Now',
		link: `${constants.studentPlatformUrl}talk-to-counsellor-form`,
	},
};
