import get from 'lodash/get';

export const getScholarshipCardData = (data) => {
	const university = get(data, 'universities[0]') || {};
	let universityLocation = get(university, 'location.state') || '';
	universityLocation =
		universityLocation && get(university, 'location.country')
			? `${universityLocation} | ${university.location.country}`
			: get(university, 'location.country')
			? university.location.country
			: '';

	const amountDetails = data.scholarship_amount,
		amountValue = get(amountDetails, 'discount_amount');

	return {
		name: data.name,
		slug: data.slug,
		universityId: university._id,
		universityName: university.name,
		universityLocation: universityLocation,
		level: data.level ? data.level.join(', ') : '',
		link: `/scholarships/${data.slug}`,
		amountlabelDynamic: !!amountValue,
		amount: amountValue
			? amountDetails.waiver_type == 'percentage'
				? `Upto ${amountValue} % of Tuition fee`
				: `${get(amountDetails, 'currency') || ''} ${amountValue}`
			: '',
	};
};

export const generateGeneralDetailsList = (generalDetails) => {
	let list = '';

	if (generalDetails.university) {
		const university = generalDetails.university;
		if (university.name)
			list += `<li><b>University Name:</b> ${university.name}</li>\n`;

		if (university.location) {
			let location = [];
			if (university.location.city) {
				location.push(university.location.city);
			}
			if (university.location.state) {
				location.push(university.location.state);
			}
			if (university.location.country) {
				location.push(university.location.country);
			}

			if (location.length) {
				list += `<li><b>Location:</b> ${location.join(', ')}</li>\n`;
			}
		}
	}

	if (generalDetails.scholarshipName)
		list += `<li><b>Scholarship Name:</b> ${generalDetails.scholarshipName}</li>\n`;

	if (generalDetails.amount)
		list += `<li><b>The total amount of the Scholarship:</b> ${generalDetails.amount}</li>\n`;

	if ((generalDetails.level || []).length) {
		list += '<li><b>Level of Education:</b> ';
		generalDetails.level.forEach((level) => (list += `${level}, `));
		list = list.slice(0, -2);
		list += '</li>\n';
	}
	if (generalDetails.merit)
		list += `<li><b>Type of Scholarship:</b> ${generalDetails.merit}</li>\n`;

	if (generalDetails.no_of_scholarships)
		list += `<li><b>Number of Scholarships:</b> ${generalDetails.no_of_scholarships}</li>\n`;

	if (generalDetails.mergeable)
		list += `<li><b>Applicable with other scholarships:</b> ${generalDetails.mergeable}</li>\n`;

	return list ? `<ul>${list}</ul>` : null;
};

export const generateEligibilityList = (eligibility) => {
	let list = '';
	if ((eligibility.country || []).length) {
		list += '<li><b>Country:</b> ';
		eligibility.country.forEach((country) => (list += `${country}, `));
		list = list.slice(0, -2);
		list += '</li>\n';
	}
	if (eligibility.score)
		list += `<li><b>Score/Percentage:</b> ${eligibility.score}</li>\n`;

	if ((eligibility.gender || []).length) {
		list += '<li><b>Gender:</b> ';
		if (eligibility.gender.includes('All')) {
			list += 'All';
		} else {
			eligibility.gender.forEach((gender) => (list += `${gender}, `));
			list = list.slice(0, -2);
		}
		list += '</li>\n';
	}

	if ((eligibility.exam || []).length) {
		list += '<li><b>Exam:</b> ';
		eligibility.exam.forEach((exam) => (list += `${exam}, `));
		list = list.slice(0, -2);
		list += '</li>\n';
	}
	if ((eligibility.program || []).length) {
		list += '<li><b>Program:</b> ';
		eligibility.program.forEach((program) => (list += `${program}, `));
		list = list.slice(0, -2);
		list += '</li>\n';
	}
	if (eligibility.merit)
		list += `<li><b>Merit:</b> ${eligibility.merit}</li>\n`;

	if (eligibility.description)
		list += `<li><b>Eligibility Description:</b> ${eligibility.description}</li>\n`;

	return list ? `<ul>${list}</ul>` : null;
};

export const generateAboutList = (aboutDetails) => {
	let list = '';
	if (aboutDetails.description)
		list += `<li> ${aboutDetails.description}</li>\n`;

	if (aboutDetails.additional_notes)
		list += `<li><b>Additional Info:</b> ${aboutDetails.additional_notes}</li>\n`;

	if (aboutDetails.page_link)
		list += `<li><b>University Links:</b><a href=${aboutDetails.page_link} target="_blank"> ${aboutDetails.page_link} </a></li>\n`;

	return list ? `<ul>${list}</ul>` : null;
};

export const generateApplicationList = (applicationProcessDetails) => {
	let list = '';
	if (applicationProcessDetails.applicationLink)
		list += `<li><b>Application Links:</b> ${applicationProcessDetails.applicationLink}</li>\n`;

	if (applicationProcessDetails.applicationProcess)
		list += `<li><b>Application Process:</b> ${applicationProcessDetails.applicationProcess}</li>\n`;

	return list ? `<ul>${list}</ul>` : null;
};

export const generateContactList = (contactDetails) => {
	let list = '';
	if (contactDetails.contactEmail)
		list += `<li><b>Contact Email:</b> ${contactDetails.contactEmail}</li>\n`;

	if (contactDetails.contactPhone)
		list += `<li><b>Contact Phone:</b> ${contactDetails.contactPhone}</li>\n`;

	if (contactDetails.contactLink)
		list += `<li><b>Contact Link:</b> ${contactDetails.contactLink}</li>\n`;

	if (contactDetails.contactDescription)
		list += `<li><b>Contact Description:</b> ${contactDetails.contactDescription}</li>\n`;

	return list ? `<ul>${list}</ul>` : null;
};

export const formatLastUpdated = (inputDate) => {
	const months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
		date = new Date(inputDate),
		day = date.getDate(),
		month = months[date.getMonth()],
		year = date.getFullYear();

	const dayWithSuffix =
		day +
		(day % 10 === 1 && day !== 11
			? 'st'
			: day % 10 === 2 && day !== 12
			? 'nd'
			: day % 10 === 3 && day !== 13
			? 'rd'
			: 'th');

	const formattedString = `Last updated on ${dayWithSuffix} ${month} ${year}`;
	return formattedString;
};
